.Player {
  text-align: center;
}
.PlayerControls {
  height: 100%;
}
.AlbumArt {
  float:left;
  margin-right: 10px;
  padding: 5px;
}
.AlbumInfo {
  margin: 0px;
  text-align: left;
}
