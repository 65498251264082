.ManagedPlaylistItemImage {
  max-width: 80px;
  height: auto;
}

.PlaylistControls {
  text-align: left;
}

.Notes {
  float:left;
  margin-right: 3px;
  align-self: center;
}
