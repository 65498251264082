@use "./components/Nav.scss";
// @use "./features/player/Player.module.scss";
@use "./features/favoritePlaylists/FavoritePlaylists.module.scss";
@use "./features/managedPlaylists/ManagedPlaylists.module.scss";
@use "./features/spotifyPlaylists/SpotifyPlaylists.module.scss";

/* For light mode */
:root[data-mantine-color-scheme="light"] body {
  --mantine-color-body: #F5F5F5;
}
/* For dark mode */
:root[data-mantine-color-scheme="dark"] body {
  --mantine-color-body: #0f0f0f;
}

.AppLogo {
  width: 350px;
  height:auto;
}
.SpotifyLogo {
  max-width: 32px;
  height: auto;
  margin-left: 10px;
}
.Avatar {
  float: left;
}
.ManageButton {
  text-align: right;
}
