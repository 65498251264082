.FavoritePlaylistItem {
  text-align: left;
}

.FavoritePlaylistItem > img {
  max-height: 100px;
  width: auto;
  float:left;
}

.FavoritePlaylistItem.ManageButton {
  vertical-align: bottom;
}

.FavoritePlaylistsList > article > header {
  text-align: right;
}
