.SpotifyPlaylistItem {
  text-align: left;
}

.PlaylistDetails {
  display: inline-block;
  clear:left;
  text-align: left;
}


.SpotifyPlaylistsList > article > header {
  text-align: right;
}
