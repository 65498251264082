.hero {
    position: relative;
    background-image: url(https://cdn.midjourney.com/4a934183-2eba-4642-9e1e-eb93f98af2bc/0_0.png);
    background-size: cover;
    background-position: bottom;
    color: var(--mantine-color-white);
    border-radius: 10px;
}

.container {
    z-index: 1;
    position: relative;
}

.AppLogo {
    display: inline-block !important;
}
